var dropdown = document.getElementsByClassName("dropdown");

function log(event) {
  dropdown[0].style.display = "none";
  dropdown[1].style.display = "none";
  dropdown[2].style.display = "none";
  dropdown[3].style.display = "none";
  setInterval(function () {
    dropdown[0].removeAttribute("style");
    dropdown[1].removeAttribute("style");
    dropdown[2].removeAttribute("style");
    dropdown[3].removeAttribute("style");
  }, 3000);
}