// STICKY NAVBAR & BUTTONS

var navbar = document.getElementById("navbar");
var sticky = navbar.offsetTop;
var buttons = document.getElementById("buttons");

window.onscroll = function () {
  navbarScroll();
 
};

function navbarScroll() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
  }
  if(buttons){
    if (navbar.classList.contains("sticky")) {
      buttonsScroll();
      // buttons.style.position = "fixed";
      // buttons.style.top = "42px";
      // buttons.style.right = "110px";
    } else {
        // buttons.style.position = "absolute";
        // buttons.style.top = "80px"; 
        // buttons.style.right = "-60px";
    }
  }
}

function buttonsScroll() {
  if (window.pageYOffset >= 150) {
    // buttons.classList.add("stickyButtons");
    buttons.style.position = "fixed";
    buttons.style.top = "42px";
      buttons.style.right = "110px";
  } else {
    // buttons.classList.remove("stickyButtons");
     buttons.style.position = "absolute";
        buttons.style.top = "80px"; 
        buttons.style.right = "-60px";
  }
};