// HEADER SLIDER

var sliderImagesLinks = [
  "background-image:url('/images/header_slider_photo.png');",
  "background-image:url('/images/header_slider_photo_2.jpg');",
  "background-image:url('/images/header_slider_photo_3.jpg');"
];

var sliderContainer = document.getElementById('container-slider-image');

if (sliderContainer) {
  var firstSliderControlLeft = document.getElementById('firstSliderControlLeft');
  var firstSliderControlRight = document.getElementById('firstSliderControlRight');
  var n = 0;

  sliderContainer.style.cssText = sliderImagesLinks[n];


  firstSliderControlLeft.onclick = function (e) {
    if (n < sliderImagesLinks.length - 1) {
      n++;
    } else {
      n = 0
    };
    sliderContainer.style.cssText = sliderImagesLinks[n];
  };

  firstSliderControlRight.onclick = function (e) {
    if (n < sliderImagesLinks.length - 1) {
      n++;
    } else {
      n = 0
    };
    sliderContainer.style.cssText = sliderImagesLinks[n];
  };
}