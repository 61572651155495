var inputName = document.getElementById('name');
var labelName = document.getElementById('label_name');

if (inputName && labelName) {
  labelName.style.cursor = 'pointer';
  labelName.addEventListener('click', function () {
    labelName.classList.add('hide');
    inputName.focus();
  });

  inputName.addEventListener('focus', function () {
    labelName.classList.add('hide');
  });

  inputName.addEventListener('blur', function () {
    if (!inputName.value) {
      labelName.classList.remove('hide');
    }
  });
}

var inputCompany = document.getElementById('company');
var labelCompany = document.getElementById('label_company');

if (inputCompany && labelCompany) {

  labelCompany.style.cursor = 'pointer';
  labelCompany.addEventListener('click', function () {
    labelCompany.classList.add('hide');
    inputCompany.focus();
  });

  inputCompany.addEventListener('focus', function () {
    labelCompany.classList.add('hide');
  });

  inputCompany.addEventListener('blur', function () {
    if (!inputCompany.value) {
      labelCompany.classList.remove('hide');
    }
  });
}


var inputCompanySite = document.getElementById('company_site');
var labelCompanySite = document.getElementById('label_company_site');

if (inputCompanySite && labelCompanySite) {

  labelCompanySite.style.cursor = 'pointer';
  labelCompanySite.addEventListener('click', function () {
    labelCompanySite.classList.add('hide');
    inputCompanySite.focus();
  });

  inputCompanySite.addEventListener('focus', function () {
    labelCompanySite.classList.add('hide');
  });

  inputCompanySite.addEventListener('blur', function () {
    if (!inputCompanySite.value) {
      labelCompanySite.classList.remove('hide');
    }
  });
}


var inputEmail = document.getElementById('email');
var labelEmail = document.getElementById('label_email');
if (inputEmail && labelEmail) {
  labelEmail.style.cursor = 'pointer';
  labelEmail.addEventListener('click', function () {
    labelEmail.classList.add('hide');
    inputEmail.focus();
  });

  inputEmail.addEventListener('focus', function () {
    labelEmail.classList.add('hide');
  });

  inputEmail.addEventListener('blur', function () {
    if (!inputEmail.value) {
      labelEmail.classList.remove('hide');
    }
  });
}

var inputTel = document.getElementById('tel');
var labelTel = document.getElementById('label_tel');
if (inputTel && labelTel) {
  labelTel.style.cursor = 'pointer';
  labelTel.addEventListener('click', function () {
    labelTel.classList.add('hide');
    inputTel.focus();
  });

  inputTel.addEventListener('focus', function () {
    labelTel.classList.add('hide');
  });

  inputTel.addEventListener('blur', function () {
    if (!inputTel.value) {
      labelTel.classList.remove('hide');
    }
  });
}

var inputCountryLoading = document.getElementById('country_loading');
var labelCountryLoading = document.getElementById('label_country_loading');
if (inputCountryLoading && labelCountryLoading) {
  labelCountryLoading.style.cursor = 'pointer';
  labelCountryLoading.addEventListener('click', function () {
    labelCountryLoading.classList.add('hide');
    inputCountryLoading.focus();
  });

  inputCountryLoading.addEventListener('focus', function () {
    labelCountryLoading.classList.add('hide');
  });

  inputCountryLoading.addEventListener('blur', function () {
    if (!inputCountryLoading.value) {
      labelCountryLoading.classList.remove('hide');
    }
  });
}

var inputCityLoading = document.getElementById('city_loading');
var labelCityLoading = document.getElementById('label_city_loading');
if (inputCityLoading && labelCityLoading) {
  labelCityLoading.style.cursor = 'pointer';
  labelCityLoading.addEventListener('click', function () {
    labelCityLoading.classList.add('hide');
    inputCityLoading.focus();
  });

  inputCityLoading.addEventListener('focus', function () {
    labelCityLoading.classList.add('hide');
  });

  inputCityLoading.addEventListener('blur', function () {
    if (!inputCityLoading.value) {
      labelCityLoading.classList.remove('hide');
    }
  });
}


var inputCountryDestination = document.getElementById('country_destination');
var labelCountryDestination = document.getElementById('label_country_destination');
if (inputCountryDestination && labelCountryDestination) {
  labelCountryDestination.style.cursor = 'pointer';
  labelCountryDestination.addEventListener('click', function () {
    labelCountryDestination.classList.add('hide');
    inputCountryDestination.focus();
  });

  inputCountryDestination.addEventListener('focus', function () {
    labelCountryDestination.classList.add('hide');
  });

  inputCountryDestination.addEventListener('blur', function () {
    if (!inputCountryDestination.value) {
      labelCountryDestination.classList.remove('hide');
    }
  });
}

var inputCityDestination = document.getElementById('city_destination');
var labelCityDestination = document.getElementById('label_city_destination');
if (inputCityDestination && labelCityDestination) {
  labelCityDestination.style.cursor = 'pointer';
  labelCityDestination.addEventListener('click', function () {
    labelCityDestination.classList.add('hide');
    inputCityDestination.focus();
  });

  inputCityDestination.addEventListener('focus', function () {
    labelCityDestination.classList.add('hide');
  });

  inputCityDestination.addEventListener('blur', function () {
    if (!inputCityDestination.value) {
      labelCityDestination.classList.remove('hide');
    }
  });
}

var inputCustomsClearance = document.getElementById('customs_clearance');
var labelCustomsClearance = document.getElementById('label_customs_clearance');
if (inputCustomsClearance && labelCustomsClearance) {
  labelCustomsClearance.style.cursor = 'pointer';
  labelCustomsClearance.addEventListener('click', function () {
    labelCustomsClearance.classList.add('hide');
    inputCustomsClearance.focus();
  });

  inputCustomsClearance.addEventListener('focus', function () {
    labelCustomsClearance.classList.add('hide');
  });

  inputCustomsClearance.addEventListener('blur', function () {
    if (!inputCustomsClearance.value) {
      labelCustomsClearance.classList.remove('hide');
    }
  });
}

var inputCargo = document.getElementById('cargo');
var labelCargo = document.getElementById('label_cargo');
if (inputCargo && labelCargo) {
  labelCargo.style.cursor = 'pointer';
  labelCargo.addEventListener('click', function () {
    labelCargo.classList.add('hide');
    inputCargo.focus();
  });

  inputCargo.addEventListener('focus', function () {
    labelCargo.classList.add('hide');
  });

  inputCargo.addEventListener('blur', function () {
    if (!inputCargo.value) {
      labelCargo.classList.remove('hide');
    }
  });
}

var inputWeight = document.getElementById('weight');
var labelWeight = document.getElementById('label_weight');
if (inputWeight && labelWeight) {

  labelWeight.style.cursor = 'pointer';
  labelWeight.addEventListener('click', function () {
    labelWeight.classList.add('hide');
    inputWeight.focus();
  });

  inputWeight.addEventListener('focus', function () {
    labelWeight.classList.add('hide');
  });

  inputWeight.addEventListener('blur', function () {
    if (!inputWeight.value) {
      labelWeight.classList.remove('hide');
    }
  });
}

var inputBulk = document.getElementById('bulk');
var labelBulk = document.getElementById('label_bulk');

if (inputBulk && labelBulk) {
  labelBulk.style.cursor = 'pointer';
  labelBulk.addEventListener('click', function () {
    labelBulk.classList.add('hide');
    inputBulk.focus();
  });

  inputBulk.addEventListener('focus', function () {
    labelBulk.classList.add('hide');
  });

  inputBulk.addEventListener('blur', function () {
    if (!inputBulk.value) {
      labelBulk.classList.remove('hide');
    }
  });
}

var buttonToogle = document.getElementById('button-toogle');
if (buttonToogle) {
  buttonToogle.onclick = function (e) {
    e.preventDefault();
    toogleCountryLoading = inputCountryLoading.value;
    toogleCountryDestination = inputCountryDestination.value;

    toogleCityLoading = inputCityLoading.value;
    toogleCityDestination = inputCityDestination.value;

    inputCountryLoading.value = toogleCountryDestination;
    inputCountryDestination.value = toogleCountryLoading;

    inputCityLoading.value = toogleCityDestination;
    inputCityDestination.value = toogleCityLoading;

    if (!inputCountryLoading.value) {
      labelCountryLoading.classList.remove('hide');
    } else {
      labelCountryLoading.classList.add('hide');
    }

    if (!inputCountryDestination.value) {
      labelCountryDestination.classList.remove('hide');
    } else {
      labelCountryDestination.classList.add('hide');
    }

    if (!inputCityLoading.value) {
      labelCityLoading.classList.remove('hide');
    } else {
      labelCityLoading.classList.add('hide');
    }

    if (!inputCityDestination.value) {
      labelCityDestination.classList.remove('hide');
    } else {
      labelCityDestination.classList.add('hide');
    }
  }
}