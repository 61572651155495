var tooltipImage = document.getElementById('tooltip_image');
var tooltipIcon = document.getElementById('tooltip_icon');
var theTooltip = document.getElementById('tooltip');

function tooltip() {
  tooltipImage.addEventListener('click', function () {
    if (theTooltip.classList.contains('visually-hidden')) {
      theTooltip.classList.remove('visually-hidden');
      tooltipIcon.style.background = '#FE5000';
      tooltipIcon.style.color = '#FFFFFF';
    } else {
      theTooltip.classList.add('visually-hidden');
      tooltipIcon.style.background = '#FFFFFF';
      tooltipIcon.style.color = '#191E2D';
    };
  });
}