// STICKY NAVBAR & BUTTONS

var navbar = document.getElementById("navbar");
var sticky = navbar.offsetTop;
var buttons = document.getElementById("buttons");

window.onscroll = function () {
  navbarScroll();
};

function navbarScroll() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky")
  } else {
    navbar.classList.remove("sticky");
  }
  if(buttons){
    if (navbar.classList.contains("sticky")) {
      buttons.style.position = "fixed";
      buttons.style.top = "42px";
    } else {
        buttons.style.position = "absolute";
        buttons.style.top = "261px"; 
    }
  }
}
