window.onload = function () {
  /*
   *    Page Dynamics
   */
  var truckSelect = document.getElementById('type_of_transportation_truck');
  var cargoSelect = document.getElementById('type_of_transportation_cargo');
  var truckContent = document.getElementById('content_truck');
  var cargoContent = document.getElementById('content_cargo');
  var deliveryRequest = document.getElementById('delivery_request');

  var formTruck = document.getElementById('form_truck_dusseldorf_moscow');

  truckSelect.addEventListener('click', function () {
    cargoContent.classList.add('visually-hidden');

    truckContent.classList.remove('visually-hidden');
  });

  cargoSelect.addEventListener('click', function () {
    cargoContent.classList.remove('visually-hidden');

    truckContent.classList.add('visually-hidden');
    deliveryRequest.classList.add('visually-hidden');
    formTruck.classList.add('visually-hidden');

    var tooltipImage = document.getElementById('tooltip_image');
    var tooltipIcon = document.getElementById('tooltip_icon');
    var theTooltip = document.getElementById('tooltip');

    if (tooltipImage) {
      tooltipImage.addEventListener('mouseover', function () {
        if (theTooltip.classList.contains('visually-hidden')) {
          theTooltip.classList.remove('visually-hidden');
          tooltipIcon.style.background = '#FE5000';
          tooltipIcon.style.color = '#FFFFFF';
        }
      });

      tooltipImage.addEventListener('mouseout', function () {
        theTooltip.classList.add('visually-hidden');
        tooltipIcon.style.background = '#FFFFFF';
        tooltipIcon.style.color = '#191E2D';
      });

      tooltipIcon.addEventListener('mouseover', function () {
        if (theTooltip.classList.contains('visually-hidden')) {
          theTooltip.classList.remove('visually-hidden');
          tooltipIcon.style.background = '#FE5000';
          tooltipIcon.style.color = '#FFFFFF';
        }
      })

      tooltipIcon.addEventListener('mouseout', function () {
        theTooltip.classList.add('visually-hidden');
        tooltipIcon.style.background = '#FFFFFF';
        tooltipIcon.style.color = '#191E2D';
      });
    }

    var chinaSelect = document.getElementById('china_select');
    var europeSelect = document.getElementById('europe_select');
    var USASelect = document.getElementById('usa_select');

    var routeCargoFromEurope = document.getElementById('groupage_cargo_from_europe');
    var routeCargoFromChina = document.getElementById('groupage_cargo_from_china');
    var routeCargoFromUSA = document.getElementById('groupage_cargo_from_usa');

    if (chinaSelect) {
      var chinaButtons = document.getElementById('china-buttons');

      chinaSelect.addEventListener('click', function () {
        routeCargoFromEurope.classList.add('visually-hidden');
        routeCargoFromUSA.classList.add('visually-hidden');

        chinaButtons.classList.remove('visually-hidden');
        routeCargoFromChina.classList.remove('visually-hidden');
      });

      europeSelect.addEventListener('click', function () {
        chinaButtons.classList.add('visually-hidden');
        routeCargoFromUSA.classList.add('visually-hidden');
        routeCargoFromChina.classList.add('visually-hidden');

        routeCargoFromEurope.classList.remove('visually-hidden');
      });

      USASelect.addEventListener('click', function () {
        chinaButtons.classList.add('visually-hidden');
        routeCargoFromChina.classList.add('visually-hidden');
        routeCargoFromEurope.classList.add('visually-hidden');

        routeCargoFromUSA.classList.remove('visually-hidden');
      });
    }

    var inputDirectTrain = document.getElementById('input_direct_train');
    var labelDirectTrain = document.getElementById('label_direct_train');
    var inputSeaPlusTrain = document.getElementById('input_sea_plus_train');
    var labelSeaPlusTrain = document.getElementById('label_sea_plus_train');
    var inputSeaPlusAuto = document.getElementById('input_sea_plus_auto');
    var labelSeaPlusAuto = document.getElementById('label_sea_plus_auto');

    if (inputDirectTrain) {
      if (inputDirectTrain.checked) {
        labelDirectTrain.classList.add('orange');
      };

      inputDirectTrain.addEventListener('click', function () {
        labelSeaPlusTrain.classList.remove('orange');
        labelSeaPlusAuto.classList.remove('orange');

        labelDirectTrain.classList.add('orange');
      })

      inputSeaPlusTrain.addEventListener('click', function () {
        labelDirectTrain.classList.remove('orange');
        labelSeaPlusAuto.classList.remove('orange');

        labelSeaPlusTrain.classList.add('orange');
      })

      inputSeaPlusAuto.addEventListener('click', function () {
        labelDirectTrain.classList.remove('orange');
        labelSeaPlusTrain.classList.remove('orange');

        labelSeaPlusAuto.classList.add('orange');
      })
    }
  })

  /*
   *    Calculator Logics
   */

  /*
   *    Loading Points
   */
  var loadingPointCountryGermany = document.getElementById('loading_point_country_germany');
  var loadingPointGermanyCities = document.getElementById('loading_point_germany_cities');

  var loadingPointCountryItaly = document.getElementById('loading_point_country_italy');
  var loadingPointItalyCities = document.getElementById('loading_point_italy_cities');

  var loadingPointCountryFrance = document.getElementById('loading_point_country_france');
  var loadingPointFranceCities = document.getElementById('loading_point_france_cities');

  var loadingPointCountryNetherlands = document.getElementById('loading_point_country_netherlands');
  var loadingPointNetherlandsCities = document.getElementById('loading_point_netherlands_cities');

  var loadingPointCountryBelgium = document.getElementById('loading_point_country_belgium');
  var loadingPointBelgiumCities = document.getElementById('loading_point_belgium_cities');

  var loadingPointCountrySpain = document.getElementById('loading_point_country_spain');
  var loadingPointSpainCities = document.getElementById('loading_point_spain_cities');

  var loadingPointCountrySlovakia = document.getElementById('loading_point_country_slovakia');
  var loadingPointSlovakiaCities = document.getElementById('loading_point_slovakia_cities');

  var loadingPointCountryCzechRepublic = document.getElementById('loading_point_country_czech_republic');
  var loadingPointCzechRepublicCities = document.getElementById('loading_point_czech_republic_cities');

  var loadingPointCountryPoland = document.getElementById('loading_point_country_poland');
  var loadingPointPolandCities = document.getElementById('loading_point_poland_cities');

  var loadingPointCountryEngland = document.getElementById('loading_point_country_england');
  var loadingPointEnglandCities = document.getElementById('loading_point_england_cities');

  var loadingPointCountryAustria = document.getElementById('loading_point_country_austria');
  var loadingPointAustriaCities = document.getElementById('loading_point_austria_cities');

  var loadingPointCountryHungary = document.getElementById('loading_point_country_hungary');
  var loadingPointHungaryCities = document.getElementById('loading_point_hungary_cities');

  var loadingPointCountrySwitzerland = document.getElementById('loading_point_country_switzerland');
  var loadingPointSwitzerlandCities = document.getElementById('loading_point_switzerland_cities');


  var loadingPointCountryFinland = document.getElementById('loading_point_country_finland');
  var loadingPointFinlandCities = document.getElementById('loading_point_finland_cities');

  var loadingPointCountryTurkey = document.getElementById('loading_point_country_turkey');
  var loadingPointTurkeyCities = document.getElementById('loading_point_turkey_cities');

  var loadingPointCountryDenmark = document.getElementById('loading_point_country_denmark');
  var loadingPointDenmarkCities = document.getElementById('loading_point_denmark_cities');

  var loadingPointCountryRomania = document.getElementById('loading_point_country_romania');
  var loadingPointRomaniaCities = document.getElementById('loading_point_romania_cities');

  var loadingPointCountryBulgaria = document.getElementById('loading_point_country_bulgaria');
  var loadingPointBulgariaCities = document.getElementById('loading_point_bulgaria_cities');


  var loadingPointCountrySweden = document.getElementById('loading_point_country_sweden');
  var loadingPointSwedenCities = document.getElementById('loading_point_sweden_cities');

  var loadingPointCountryGreece = document.getElementById('loading_point_country_greece');
  var loadingPointGreeceCities = document.getElementById('loading_point_greece_cities');

  loadingPointCountryGermany.addEventListener('click', function () {
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointGermanyCities.classList.remove('visually-hidden');
  });

  loadingPointCountryItaly.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointItalyCities.classList.remove('visually-hidden');
  });

  loadingPointCountryFrance.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointFranceCities.classList.remove('visually-hidden');
  });

  loadingPointCountryNetherlands.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointNetherlandsCities.classList.remove('visually-hidden');
  });

  loadingPointCountryBelgium.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointBelgiumCities.classList.remove('visually-hidden');
  });

  loadingPointCountrySpain.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointSpainCities.classList.remove('visually-hidden');
  });

  loadingPointCountrySlovakia.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointSlovakiaCities.classList.remove('visually-hidden');
  });

  loadingPointCountryCzechRepublic.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointCzechRepublicCities.classList.remove('visually-hidden');
  });

  loadingPointCountryPoland.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointPolandCities.classList.remove('visually-hidden');
  });

  loadingPointCountryEngland.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointEnglandCities.classList.remove('visually-hidden');
  });

  loadingPointCountryAustria.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointAustriaCities.classList.remove('visually-hidden');
  });

  loadingPointCountryHungary.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointHungaryCities.classList.remove('visually-hidden');
  });

  loadingPointCountrySwitzerland.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointSwitzerlandCities.classList.remove('visually-hidden');
  });

  loadingPointCountryFinland.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointFinlandCities.classList.remove('visually-hidden');
  });

  loadingPointCountryTurkey.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointTurkeyCities.classList.remove('visually-hidden');
  });

  loadingPointCountryDenmark.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointDenmarkCities.classList.remove('visually-hidden');
  });

  loadingPointCountryRomania.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointRomaniaCities.classList.remove('visually-hidden');
  });

  loadingPointCountryBulgaria.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointBulgariaCities.classList.remove('visually-hidden');
  });

  loadingPointCountrySweden.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointGreeceCities.classList.add('visually-hidden');

    loadingPointSwedenCities.classList.remove('visually-hidden');
  });

  loadingPointCountryGreece.addEventListener('click', function () {
    loadingPointGermanyCities.classList.add('visually-hidden');
    loadingPointItalyCities.classList.add('visually-hidden');
    loadingPointFranceCities.classList.add('visually-hidden');
    loadingPointNetherlandsCities.classList.add('visually-hidden');
    loadingPointBelgiumCities.classList.add('visually-hidden');
    loadingPointSpainCities.classList.add('visually-hidden');
    loadingPointSlovakiaCities.classList.add('visually-hidden');
    loadingPointCzechRepublicCities.classList.add('visually-hidden');
    loadingPointPolandCities.classList.add('visually-hidden');
    loadingPointEnglandCities.classList.add('visually-hidden');
    loadingPointAustriaCities.classList.add('visually-hidden');
    loadingPointHungaryCities.classList.add('visually-hidden');
    loadingPointSwitzerlandCities.classList.add('visually-hidden');
    loadingPointFinlandCities.classList.add('visually-hidden');
    loadingPointTurkeyCities.classList.add('visually-hidden');
    loadingPointDenmarkCities.classList.add('visually-hidden');
    loadingPointRomaniaCities.classList.add('visually-hidden');
    loadingPointBulgariaCities.classList.add('visually-hidden');
    loadingPointSwedenCities.classList.add('visually-hidden');

    loadingPointGreeceCities.classList.remove('visually-hidden');
  });
  /*
   *    Unloading Points
   */
  var unloadingPointCountryRussia = document.getElementById('unloading_point_country_russia');
  var unloadingPointRussiaCities = document.getElementById('unloading_point_russia_cities');

  var unloadingPointCountryBelarus = document.getElementById('unloading_point_country_belarus');
  var unloadingPointBelarusCities = document.getElementById('unloading_point_belarus_cities');

  var unloadingPointCountryKazakhstan = document.getElementById('unloading_point_country_kazakhstan');
  var unloadingPointKazakhstanCities = document.getElementById('unloading_point_kazakhstan_cities');

  unloadingPointCountryRussia.addEventListener('click', function () {
    unloadingPointBelarusCities.classList.add('visually-hidden');
    unloadingPointKazakhstanCities.classList.add('visually-hidden');

    unloadingPointRussiaCities.classList.remove('visually-hidden');
  });

  unloadingPointCountryBelarus.addEventListener('click', function () {
    unloadingPointRussiaCities.classList.add('visually-hidden');
    unloadingPointKazakhstanCities.classList.add('visually-hidden');

    unloadingPointBelarusCities.classList.remove('visually-hidden');
  });

  unloadingPointCountryKazakhstan.addEventListener('click', function () {
    unloadingPointRussiaCities.classList.add('visually-hidden');
    unloadingPointBelarusCities.classList.add('visually-hidden');

    unloadingPointKazakhstanCities.classList.remove('visually-hidden');
  });


  /*
   *    Calculations
   */

  var formTypeCalculate = document.getElementById('form-type_calculate');
  // var formTruck = document.getElementById('form_truck');

  formTypeCalculate.addEventListener('click', function (e) {

    var loadingPointGermanyDusseldorf = document.getElementById('loading_point_germany_dusseldorf');
    var unloadingPointRussiaMoscow = document.getElementById('unloading_point_russia_moscow');
    var formTruckDusseldorfMoscow = document.getElementById('form_truck_dusseldorf_moscow');


    var unloadingPointRussiaStPetersburg = document.getElementById('unloading_point_russia_st_petersburg');

    e.preventDefault();

    if (loadingPointGermanyDusseldorf.checked && unloadingPointRussiaMoscow.checked) {
      formTruckDusseldorfMoscow.classList.remove('visually-hidden');

      formTruckDusseldorfMoscow.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    };

    if (loadingPointGermanyDusseldorf.checked && unloadingPointRussiaStPetersburg.checked) {
      console.log('Dusseldorf -- St. Petersburg');
    };
  });

  /*
   *    Delivery Request
   */

  var submitRequestDirectAuto = document.getElementById('submit_request_direct_auto');
  var submitRequestCrossDocking = document.getElementById('submit_request_cross_docking');

  if (submitRequestDirectAuto && submitRequestCrossDocking) {
    submitRequestDirectAuto.addEventListener('click', function (click) {
      click.preventDefault();
      deliveryRequest.classList.remove('visually-hidden');

      deliveryRequest.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    })
  }

  submitRequestCrossDocking.addEventListener('click', function (click) {
    click.preventDefault();
    deliveryRequest.classList.remove('visually-hidden');

    deliveryRequest.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  })
}