var inputSearch = document.getElementById('search');
var buttonSearch = document.getElementById('button-search');

buttonSearch.onclick = function () {
  if (inputSearch.className == "visually-hidden") {
    inputSearch.classList.remove('visually-hidden');
    inputSearch.focus();
  } else {
    inputSearch.classList.add('visually-hidden');
  }

  inputSearch.addEventListener("blur", function () {
    this.classList.add('visually-hidden');
  })
}