// INTEGRATED-LOGISTICS SLIDER 

var carousel2 = document.getElementById('integrated-logistics-carousel');

if (carousel2) {
  var width2 = 261;
  var count2 = 2;
  var list2 = carousel2.querySelector('ul');
  var listElems2 = carousel2.querySelectorAll('li');
  var position2 = 0;
  var integratedLogisticsControls = document.getElementById('integrated-logistics-carousel-controls');
  
  if (listElems2.length > 2) {
    integratedLogisticsControls.classList.remove('visually-hidden');
  } else {
    integratedLogisticsControls.classList.add('visually-hidden');
  }
  
  carousel2.querySelector('.control-left').onclick = function (e) {
    e.preventDefault();
    position2 = Math.min(position2 + width2 * count2, 0)
    list2.style.marginLeft = position2 + 'px';
  };
  
  carousel2.querySelector('.control-right').onclick = function (e) {
    e.preventDefault();
    if (position2 == 0) {
      position2 = Math.max(position2 - width2 * count, -width2 * (listElems2.length - count2) + 3);
      list2.style.marginLeft = position2 + 'px';
    } else {
      position2 = 0;
      list2.style.marginLeft = position2 + 'px';
    }
  };
}