var buttonSubmit = document.getElementById('button_submit_application');

buttonSubmit.onclick = function (e) {
  var containerSubmit = document.getElementById('submit_application_container');
  var closeModal = document.getElementById('modal_close');

  e.preventDefault();
  containerSubmit.classList.remove('visually-hidden');
  closeModal.onclick = function () {
    containerSubmit.classList.add('visually-hidden');
  }
}