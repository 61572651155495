// STICKY NAVBAR & BUTTONS

var buttons = document.getElementById("buttons");

if (buttons) {
  var navbar = document.getElementById("navbar");
  var sticky = navbar.offsetTop;

  var stickyButtons = buttons.offsetTop;

  window.onscroll = function () {
    navbarScroll();
  };

  function navbarScroll() {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky");
    }
    if (navbar.classList.contains("sticky")) {
      buttons.style.position = "fixed";
      buttons.style.top = "42px";
    } else {
      buttons.style.position = "relative";
      buttons.style.top = "0";
    }
  }
}