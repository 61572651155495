var navbar = document.getElementById("navbar");
var sticky = navbar.offsetTop;
var buttons = document.getElementById("buttons");

if(buttons) {
  var stickyButtons = buttons.offsetTop;

  window.onscroll = function () {
    navbarScroll();
    buttonsScroll();
  };
  
  function navbarScroll() {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky");
    }
  }
  
  function buttonsScroll() {
    if (window.pageYOffset >= 312) {
      buttons.classList.add("stickyButtons")
    } else {
      buttons.classList.remove("stickyButtons")
    }
  };
}