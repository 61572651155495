// MORE-SERVICES CAROUSEL
var list = document.getElementById('card');
var carousel = document.getElementById('carousel');

if (carousel) {
  var cardListItem = carousel.querySelector('#carousel');
  var cardListItems = carousel.querySelectorAll('#card');
  var card = document.querySelector('.card-flex');
  var position = 0; /// arrows-btns

  var widthCarousel = parseFloat(getComputedStyle(card).width) + parseFloat((getComputedStyle(card).marginRight));
  var count = 1;


  var arrowLeftBtn = document.querySelector('.left-control-btn');
  var arrowRightBtn = document.querySelector('.right-control-btn');

  for (var i = 0; i < list.lenght; i++) {
    list[i].style.position = 'relative';
    var span = document.createElement('span');
    span.style.cssText = 'position:absolute;left:0;top:0;';
    list[i].appendChild(span);
  }

  arrowLeftBtn.addEventListener('click', function (e) {
    e.preventDefault();
    position = Math.min(position + 100 + widthCarousel * count, 0);
    list.style.marginLeft = position + 'px';
    setActivButtons(list.style.marginLeft);
  });
  arrowRightBtn.addEventListener('click', function (e) {
    e.preventDefault();

    if (position == 0) {
      position = Math.max(position - widthCarousel * count, -widthCarousel * (cardListItems.length - count));
      list.style.marginLeft = position + 'px';
      setActivButtons(list.style.marginLeft);
    } else {
      position = 0;
      setActivButtons(list.style.marginLeft);
    }
  });

  function setActivButtons(margin) {
    if (parseFloat(margin) == 0) {
      // arrowLeftBtn.classList.remove('active-control-btn' );
      arrowLeftBtn.classList.remove('hover-slider-buttons--big'); // arrowRightBtn.classList.add('active-control-btn' );

      arrowRightBtn.classList.add('hover-slider-buttons--big');
    }

    if (parseFloat(margin) !== 0) {
      // arrowLeftBtn.classList.add('active-control-btn' );
      arrowLeftBtn.classList.add('hover-slider-buttons--big');
    }

    if (parseFloat(margin) == -widthCarousel) {
      // arrowRightBtn.classList.remove('active-control-btn' );
      arrowRightBtn.classList.remove('hover-slider-buttons--big');
    }
  }
}